import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"

class StaffShuttleCapitaServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Capita staff shuttle to and from Arlington Business Centre"
          description="View route information for the Capita staff shuttle service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">Capita staff shuttle service</h1>
              <p>Leeds Kirkgate Coach Bay - Arlington Business Centre</p>
              <p>Arlington Business Centre - Kirkgate Coach Bay</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>AM Route</h2>
                <iframe
                  title="Capita staff shuttle AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1vyZkqElHSW_SRROQbWUfYcNaXvooCUw&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>

                <p>
                  <strong>AM Towards</strong> Arlington Business Centre
                </p>
                <p>
                  From Leeds Kirkgate Coach Bay, Sovereign Street Coach Bay, Arlington Business Centre.
                </p>
              </div>
              <div className="page-content__text">
                <h2>PM Route</h2>
                <iframe
                  title="Capita staff shuttle PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1gWTt28GHiLldsAN_HJI3nwmUHYt7zKY&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>

                <p>
                  <strong>PM From</strong> Arlington Business Centre
                </p>
                <p>
                  From Arlington Business Centre, Asda House on Great Wilson Street, Kirkgate Coach Bay
                </p>
              </div>
            </div>
            <div className="page-content">
              <div style={{ width: '100%' }}>
                <h2>Timetable</h2>
                <div style={{ overflowX:'auto' }}>
                  <table className="timetable" style={{ width: '100%', fontSize: '0.9em' }}>
                    <thead>
                      <tr>
                        <th style={{ minWidth: 100 }}>Inbound Stop</th>
                        <th colSpan="6">Monday - Friday <span className="mobile-hint">Swipe to see all journeys &#10142;</span></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Leeds Kirkgate Coach Bay</td>
                        <td>07:20</td>
                        <td>08:20</td>
                        <td>09:20</td>
                        <td>10:15</td>
                        <td>11:15</td>
                        <td>12:15</td>
                      </tr>
                      <tr>
                        <td>Sovereign Street Coach Bay</td>
                        <td>07:30</td>
                        <td>08:30</td>
                        <td>09:30</td>
                        <td>10:25</td>
                        <td>11:25</td>
                        <td>12:25</td>
                      </tr>
                      <tr>
                        <td>Arlington Business Centre (Arrive)</td>
                        <td>07:45</td>
                        <td>08:46</td>
                        <td>09:45</td>
                        <td>10:38</td>
                        <td>11:38</td>
                        <td>12:38</td>
                      </tr>
                      <tr>
                        <td>Arlington Business Centre (Depart)</td>
                        <td>07:47</td>
                        <td>08:48</td>
                        <td>09:47</td>
                        <td>10:45</td>
                        <td>11:45</td>
                        <td>12:45</td>
                      </tr>
                    </tbody>
                  </table>

                  <table className="timetable" style={{ width: '100%', fontSize: '0.9em' }}>
                    <thead>
                      <tr>
                        <th style={{ minWidth: 100 }}>Outbound Stop</th>
                        <th colSpan="6">Monday - Friday <span className="mobile-hint">Swipe to see all journeys &#10142;</span></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Arlington Business Centre</td>
                        <td>16:15</td>
                        <td>17:15</td>
                        <td>18:15</td>
                        <td>19:15</td>
                        <td>20:15</td>
                        <td>21:15</td>
                      </tr>
                      <tr>
                        <td>Asda House, Great Wilson Street (Alight Only)</td>
                        <td>16:30</td>
                        <td>17:30</td>
                        <td>18:30</td>
                        <td>19:30</td>
                        <td>20:30</td>
                        <td>21:30</td>
                      </tr>
                      <tr>
                        <td>Kirkgate Coach Bay (Alight Only)</td>
                        <td>16:40</td>
                        <td>17:40</td>
                        <td>18:40</td>
                        <td>19:40</td>
                        <td>20:40</td>
                        <td>21:40</td>
                      </tr>
                    </tbody>
                  </table>

                </div>

              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default StaffShuttleCapitaServicePage
